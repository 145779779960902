* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Proxima Nova';
}

.addBtn {
    margin-left: 16px;

    .ant-btn {
        background: #586f80;
        border-radius: 4px;
        height: 28px;
        color: white;
        font-weight: 600;
        text-align: center;
        border: none !important;
        transition: all 0.5s linear;
        margin: 0px 10px;

        &:hover,
        &:focus {
            color: white;
            background: #586f80;
            border: none;
        }
    }
}

.ant-tabs-top > .ant-tabs-nav {
    margin: 5px 0px 24px 0px !important;
}

.ant-btn .ant-btn-primary {
    position: absolute;
    top: 81px;
    right: 16px;
}

.abstractTabs {
    .ant-tabs-tab {
        height: 37px;
        min-width: 180px;
        border-radius: 8px !important;
        background: #f0f4f7 !important;
    }

    .ant-tabs-tab.ant-tabs-tab-with-remove {
        display: flex;
        justify-content: space-between;
        height: 37px;
        min-width: 180px;
    }

    .ant-tabs-tab.ant-tabs-tab-active {
        height: 37px;
        min-width: 180px;
        background: #fff !important;
        font-weight: 600;
        font-size: 14px;

        .ant-tabs-tab-btn {
            color: #323232 !important;
            line-height: 17px;
        }
    }

    .ant-tabs-tab-btn {
        color: #637f92;
        line-height: 17px;
    }
}

.actionbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .align-bottom {
        align-items: baseline;
    }

    h2 {
        font-size: 24px;
        font-weight: 600;
        margin-right: 8px;
        margin-bottom: 0;
    }

    .dummy-btn {
        margin-left: 16px;
    }
}

.flex {
    display: flex;
}

.align-center {
    align-items: center;
}

.align-end {
    align-items: flex-end;
}

.dropdown-group {
    margin-bottom: 20px;

    .ant-picker-input {
        ::placeholder {
            font-size: 16px;
            line-height: 17px;
            color: #586f80;
        }
    }
}

.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
    color: #586f80 !important;
}

.ant-table-column-title {
    z-index: 0 !important;
}

.ant-pagination-item-active {
    border-color: #586f80 !important;

    a {
        color: #586f80 !important;
    }
}
.ant-tag {
    border: none !important;
}

.ant-pagination-item {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.nameClick:hover {
    cursor: pointer;
}

.ant-table-thead .ant-table-cell {
    background-color: #f8fafb !important;
}

.ant-table-thead {
    tr {
        .ant-table-cell {
            font-size: 16px !important;
            font-weight: 600 !important;
            color: #637f92;
        }

        .ant-table-column-title {
            font-size: 16px !important;
        }
    }
}

.ant-table-tbody {
    .ant-tag {
        font-size: 16px;
        font-weight: 600;
    }

    tr {
        td {
            font-size: 16px !important;
            font-weight: 400;
            color: #323232 !important;
        }
    }
}

.landing-container {
    .ant-table-cell {
        max-width: 300px;
    }
}

// Date Filter
.date-filter.date-filter {
    padding-top: 8px;
    padding-bottom: 8px;
    margin-right: 6px;
    color: #586f80;
    outline: none;
    border-radius: 50px;
    line-height: 20px;
    width: 125px;

    &:focus,
    &:hover {
        color: #586f80;
        border: 1px solid #586f80;
        outline: none;
    }
}

.date-filter.ant-picker-focused {
    border-color: #586f80;
    box-shadow: none;
}
