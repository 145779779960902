.sidebar-holder {
    position: relative;
    height: 100vh;
    background: #0a0a14;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
        display: none;
    }

    .sidebar-navigation {
        max-height: calc(100% - 40px);
        overflow-y: auto;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .sidebar-header {
        display: flex;
        justify-content: center;
        padding-block: 10px 5px;
        line-height: 26px;
        text-decoration: dotted;
        border-bottom: 1px solid #3f3f3f;
    }

    .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background-color: transparent;
    }

    .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected .btn {
        color: #ededed;
        font-weight: 600;
    }

    .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected .img {
        filter: opacity(1);
    }

    .img {
        filter: opacity(0.5);
    }

    .logout-btn-wrapper {
        position: absolute;
        bottom: 0;
    }

    .logout-button {
        padding: 0;
        left: 21px;
        text-align: left;
        color: rgba(255, 255, 255, 0.65);

        &:hover {
            color: #ffffff;
        }
    }

    .logo-text {
        font-size: 14px;
        font-weight: bold;
        font-family: 'Proxima Nova';
        font-style: normal;
        line-height: 14px;
        color: #ffffff;
        display: flex;
        align-items: center;

        img {
            margin-right: 5px;
        }

        .subheading {
            margin-top: 8px;
            font-size: 16px;
            color: #efefef;
        }
    }

    .dropdown-wrapper {
        .dropdown-btn {
            color: #ffffff !important;
            border-radius: 0px !important;
            background: transparent;
            border: none;
            padding: 0 4px;

            span {
                font-size: 14px;
                font-weight: 700;
            }

            &:hover,
            &:focus {
                border: none;
            }
        }

        .checkbox-wrapper {
            margin-left: -40px;
            color: #484848;
            padding: 18px;
            margin-top: 10px;

            li {
                font-size: 16px;
                font-family: 'Proxima Nova';
            }
        }
    }
}
