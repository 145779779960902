.dropdown-wrapper {
    position: relative;
    margin-right: 6px;

    .dropdown-btn {
        display: flex;
        align-items: center;
        color: #586f80;
        outline: none;
        border-radius: 50px;
        line-height: 20px;

        span {
            display: flex;
        }

        &:focus,
        &:hover {
            color: #586f80;
            border: 1px solid #586f80;
        }
    }

    .active {
        background-color: #586f80;
        color: #fff;

        &:hover {
            background-color: #586f58;
            color: #fff;
        }
    }

    .checkbox-wrapper {
        position: absolute;
        z-index: 1;
        background: #fff;
        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
        width: 160px;
        max-height: 500px;
        overflow-y: scroll;

        .ant-checkbox-group {
            display: flex;
            flex-direction: column;
            padding: 24px 8px 0 24px;

            .ant-checkbox-group-item {
                margin: 0;
                padding-bottom: 24px;

                span {
                    color: #0a0a14;
                }
            }
        }

        .clear-btn-wrapper {
            display: flex;
            justify-content: space-between;

            .clear-btn {
                background-color: #fff;
                border: none;
                padding: 8px 12px;
                color: #767676;
                letter-spacing: 0.8px;
                cursor: pointer;

                &:hover {
                    color: #586f80;
                }
            }
        }
    }

    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #586f80;
        border-color: #586f58;
    }

    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: #586f58;
    }

    li {
        list-style: none;
    }
}
