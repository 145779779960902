.landing-box {
    display: grid;
    grid-template-columns: 180px 1fr;
    overflow: hidden;

    .landing-box-content {
        height: 100vh;
        overflow-y: auto;
        padding: 20px 15px;
    }
}
