.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.garageBlacklistModal {
    position: absolute;
    left: 40%;
    top: 30%;
    padding: 30px 50px;
    background-color: rgb(248, 245, 245);
    border: 1px solid rgb(145, 144, 144);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.confirmCard {
    display: flex;
    justify-content: center;
}

.confirmButtons {
    display: flex;
    padding: 20px 20px 0;

    .confirmBtn {
        display: block;
        margin: 10px;
        background-color: rgb(12, 11, 11);
        color: #fff;
        border-radius: 5px;
    }
}
