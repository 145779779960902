@import '~antd/dist/antd.less';
@import 'styles/font.less';

* {
    margin: 0;
    padding: 0;
}

*::-webkit-scrollbar {
    display: none; 
}

.bold{
    font-weight: bold;
}
@primary-color: #ee3a34;