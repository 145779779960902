* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.collapse1 {
    .ant-collapse {
        border: none;
    }

    .ant-collapse-item-active {
        border: none !important;
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        font-weight: 600;
        font-size: 16px;
        line-height: 16px !important;
        color: #637f92 !important;
    }

    .ant-collapse-header {
        height: 40px !important;
        background: #f0f4f7 !important;
        border: none !important;
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
        border: 1px solid #637f92;
        background: #637f92;
        border-radius: 50%;
        color: white;
    }
}

.ant-collapse-content {
    border: none !important;
}
