@font-face {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    src: url('../../assets/fonts/Proxima-Nova-normal.ttf') format('truetype'),
    url('../../assets/fonts/Proxima-Nova-normal.otf') format('opentype'),
}

@font-face {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 500;
    src: url('../../assets/fonts/Proxima-Nova-Medium.ttf') format('truetype'),
    url('../../assets/fonts/Proxima-Nova-Medium.otf') format('opentype'),
}

@font-face {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    src: url('../../assets/fonts/Proxima-Nova-Semibold.ttf') format('truetype'),
    url('../../assets/fonts/Proxima-Nova-Semibold.otf') format('opentype'),
}

@font-face {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    src: url('../../assets/fonts/Proxima-Nova-Bold.ttf') format('truetype'),
    url('../../assets/fonts/Proxima-Nova-Bold.otf') format('opentype'),
}
