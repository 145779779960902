.leftOffset {
    position: relative;
    left: 11px;

    &::after {
        content: ':';
        margin-left: 2px;
    }
}

.labelWithOffset {
    position: relative;
    left: 12px;
    white-space: pre-line;
}

// used in multipleTwinInput form field
.offset {
    margin-left: 0px;
    position: absolute;
    right: -32px;
    top: 7px;
}

// used in multiSelectInput component
.dragDropContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 10px;
    row-gap: 10px;
}

.tag {
    display: inline-block;
    padding: 5px 10px;
    border: 3px solid #f4f5f4;
    border-radius: 5px;
    cursor: move;
}

.dragDroplabel {
    min-height: 70px;
}

// used in multipleTextInput component
.mti-container {
    display: flex;
}
.extraIcons {
    position: absolute;
    left: calc(100% + 5px);
    display: flex;
    align-items: center;
    column-gap: 10px;
    height: 100%;
}
.dragger {
    cursor: move;
}
.uploadContainer {
    display: grid;
    column-gap: 10px;
}

// used in multipleTextInput and multipleTwinInpput form field
.dynamic-delete-button {
    color: #999;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s;
}
.dynamic-delete-button:hover {
    color: #777;
}
.dynamic-delete-button[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
}
[data-theme='dark'] .dynamic-delete-button {
    color: rgba(255, 255, 255, 0.45);
}
[data-theme='dark'] .dynamic-delete-button:hover {
    color: rgba(255, 255, 255, 0.65);
}
