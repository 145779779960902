* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Proxima Nova';
    font-style: normal;
}

.abstract-form {
    display: flex;
    flex-direction: column;
    padding-block: 30px 0;

    .ant-select-selection-placeholder {
        font-weight: normal;
    }

    .btn-container {
        display: flex;
        justify-content: space-between;
        justify-content: flex-start;
        align-items: center;
        margin: 0px 42px 10px 0px;

        .expert-name {
            display: flex;
            align-items: center;
            font-size: 20px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0px;
            text-align: left;

            span {
                display: flex;
                align-items: center;
                margin-left: 8px;
                height: 25px;
                border-radius: 4px;
                font-family: 'Proxima Nova';
                font-size: 16px;
                font-weight: 600;
                line-height: 16px;
            }
        }
    }

    .container1 {
        display: flex;

        .form-container {
            width: 100%;
        }
    }

    .form-container::-webkit-scrollbar {
        width: 5px;
    }

    .form-container::-webkit-scrollbar-track {
        width: 10px;
        background: #fff;
    }

    .form-container::-webkit-scrollbar-thumb {
        height: 433px;
        background: #cdd7e0;
    }

    .form-container::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    .ant-select-arrow {
        color: #323232;
    }
}

.publish-btn-container {
    display: flex;
    column-gap: 10px;
}

.publish-btn {
    .ant-btn {
        background: #586f80;
        border-radius: 2px;
        height: 32px;
        width: 95px;
        color: white;
        font-weight: 600;
        text-align: center;
        transition: all 0.5s linear;
        border: none !important;

        &:hover,
        &:focus {
            color: white;
            background: #586f80;
            border: none;
        }
    }
}

.ant-btn .ant-btn-primary {
    position: absolute;
    top: 81px;
    right: 16px;
}

.ant-form-horizontal .ant-form-item-label {
    // this aligns the labels of the form field to the left which are by default set to right
    // text-align: left;
}

/**
* if the following code is uncommented, the asterisk for required field will not be shown
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    content: '' !important;
    margin-right: 0 !important;
}

* if uncommented, the colon after the label in a form field will be hidden
.ant-form-item-label > label::after {
    content: '' !important;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    content: '' !important;
}
*/

.textArea {
    flex-direction: column !important;
}

.textAreaInput {
    width: 566px !important;
}

.ant-form-item textarea.ant-input {
    max-width: 566px !important;
    min-height: 109px !important;
    padding: 12px 9px;
}

.upload {
    flex-direction: column !important;

    .label-paragraph {
        margin-bottom: 12px;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        width: max-content;
    }
}

.ant-form-item-label > label {
    font-size: 16px !important;
}

.checkbox1 {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 300 !important;
    font-size: 16px;
    display: flex;
    align-items: flex-start;

    span {
        line-height: 14px;
    }
}

.upload-container {
    width: 566px;
}

.ant-upload.ant-upload-drag {
    margin-bottom: 12px;
}

.tags {
    .ant-tag-checkable-checked {
        background-color: #586f80;
        color: #fff !important;
    }

    .ant-tag {
        border-radius: 50px;
        padding: 6.4px 15px;
        border: 1px solid #586f80 !important;
        color: #323232;
        font-size: 14px;
        font-weight: normal;
        margin: 0 24px 15px 0;
        width: fit-content;

        &:hover {
            color: #f8fafb !important;
            background-color: #586f80 !important;
        }
    }
}

.addField-btn {
    margin-top: -10px !important;

    button {
        padding-left: 0;
        padding-top: 0;
        font-weight: 600;
        font-size: 16px;
        color: #637f92;
    }

    .ant-btn {
        border: none;

        &:active,
        &:hover,
        &:focus {
            color: #586f80 !important;
        }
    }

    .ant-btn-block {
        width: 0%;
    }
}

.addField-input .ant-input {
    width: 566px !important;
}

.ant-input {
    color: #323232 !important;
}

.ant-select-selection-item {
    font-weight: normal;
}

.ant-select-selector:hover,
.ant-select-selector:focus-within {
    border: 1px solid #586f80 !important;
    box-shadow: none !important;
}

.delete-question {
    margin-top: 9px;
    margin-left: 8px;
    height: 13px;
    cursor: pointer;
}

.tags-wrapper {
    display: grid;
    grid-template-columns: auto auto auto;
}

.addField-input-wrapper .ant-row {
    width: 566px;
}

/** is it required to overwrite the library colors using important?
.ant-input:focus,
.ant-input-focused,
.ant-input:hover,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.ant-input-affix-wrapper:focus {
    border-color: #586f80 !important;
    box-shadow: none !important;
}

.ant-picker:hover,
.ant-picker-focused,
.ant-picker-hover,
.ant-picker-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.ant-picker-affix-wrapper:focus {
    border-color: #586f80 !important;
    box-shadow: none !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #586f80 !important;
    border-color: #586f58 !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #586f58 !important;
}
*/

.nested-checkbox-input {
    align-items: center;
    justify-content: space-around;
    height: 104px;
    padding: 32px !important;
    background: rgba(240, 244, 247, 0.4);
}

// this is textEditor style
.ql-toolbar.ql-snow + .ql-container.ql-snow {
    border-top: 0px;
    height: 350px;
}

.ql-editor {
    overflow-y: scroll;
    resize: vertical;
}

.card {
    width: 705px;
    min-height: 250px;
    background: rgba(240, 244, 247, 0.4) !important;
    border: none !important;
    margin-bottom: 28px !important;

    .ant-card-body {
        .ant-row {
            align-items: flex-start;
        }
    }

    .ant-card-head-title {
        font-size: 16px;
    }
    .card-content {
        .ant-col .ant-col-8 .ant-form-item-label {
            width: 329px;
        }
    }

    .ant-card-body {
        padding: 24px 0px 24px 20px;
    }

    .card-content div {
        width: 355px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .ant-col-8 {
        flex: none;
        max-width: 45%;
    }
}

.option-card {
    border: none !important;

    .ant-form-item-explain-error {
        position: absolute;
        left: -260px;
    }

    .ant-card-head-title {
        position: relative;
        right: 23px;
        font-size: 16px;
        font-weight: 600;
    }

    .option-card-checkbox {
        position: relative;
        right: 286px;
    }

    .ant-col-8 {
        flex: none;
        max-width: 46%;
    }
}

.table {
    .ant-form-item-label {
        display: none;
    }

    .ant-form-item-control {
        max-width: 100%;

        .ant-form-item-control-input-content {
            max-width: 100% !important;
        }

        & > .table-btn-container {
            margin-top: 0;
        }
    }
}

.checkboxGroupContainer {
    display: grid;
    grid-template-columns: 45% 55%;
    column-gap: 10px;
}
