* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.ant-upload-text {
    color: #637f92 !important;
    border: none !important;
}

.ant-upload .ant-upload-btn {
    padding: 0 !important;
    border: none !important;
}

.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
    border-color: #9fb0bb !important;
}
