* {
    font-family: "Proxima Nova";
}

.flex {
    display: flex;
}

.align-center {
    align-items: center;
}

.justify-center {
    justify-content: center;
}

.dir-col {
    flex-direction: column;
}

.container {
    height: 100vh;
    width: 100%;
    display: flex;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    .logo-container {
        width: 50%;
        background-image: url("../../assets/png/loginbg.png");
        background-repeat: no-repeat, repeat;
        background-size: cover;

        h1 {
            font-size: 64.5px;
            line-height: 65px;
            margin: 0px;
            padding-left: 14px;
        }

        .logo {
            width: 273px;
            height: 80px;
        }
    }

    .form-container {
        width: 50%;
        margin: auto;
        z-index: 1;
        padding: 80px;

        .box {
            width: 500px;

            h2 {
                font-size: 55px;
                font-weight: bold;
                padding-bottom: 50px;
            }

            .ant-input {
                padding: 12px !important;
            }

            .ant-form-item-control-input-content {
                width: 100% !important;
                max-width: 446px !important;
            }

            .ant-form {
                width: 446px;
            }

            .ant-form-item-control-input {
                width: 100% !important;
            }

            .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
                display: none !important;
            }

            .ant-checkbox-wrapper {
                color: #637f92 !important;
            }

            .ant-input:focus,
            .ant-input-focused,
            .ant-input:hover,
            .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
            .ant-input-affix-wrapper:focus {
                border-color: #586f80 !important;
                box-shadow: none !important;
            }

            .ant-checkbox-checked .ant-checkbox-inner {
                background-color: #586f80 !important;
                border-color: #586f58 !important;
            }

            .ant-checkbox-wrapper:hover .ant-checkbox-inner,
            .ant-checkbox:hover .ant-checkbox-inner,
            .ant-checkbox-input:focus + .ant-checkbox-inner {
                border-color: #586f58 !important;
            }

            .ant-input-affix-wrapper {
                padding: 0px 4px 0px 0px !important;
            }

            .ant-form-item-control-input-content {
                display: flex !important;
                justify-content: space-between !important;
            }

            .ant-btn-primary {
                border-color: #586f80 !important;
                background: #586f80 !important;
            }
        }
    }
}

.login-form-forgot {
    color: #637f92;

    &:hover {
        color: #586f80;
    }
}

.login-form-button {
    height: 44px !important;
}

.loginDashboard {
    background: #000;
    color: #fff;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        font-size: 24px;
        margin-bottom: 56px;
    }

    h2 {
        color: #fff;
        font-size: 38.3px;
        margin-bottom: 0px;
    }

    .beauty-travel-img {
        width: 149px;
        height: 131px;
    }

    .logo {
        color: #fff;
        width: 30px;
        height: 30px;
        margin-right: 12px;
    }

    .wrapper {
        margin: 24px;
    }

    .logo-wrapper {
        padding-top: 24px;
    }
}
