.abstractTable {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: normal;

    h2 {
        font-size: 24px;
        color: #323232;
        font-family: 'Proxima Nova';
        font-weight: 600;
        margin-bottom: 0;
        margin-right: 10px;
    }

    .justify-center {
        justify-content: center;
    }

    .justify-sb {
        justify-content: space-between;
    }

    .align-center {
        align-items: center;
    }

    .chat-icon {
        span {
            color: #637f92;
            font-size: 16px;
            line-height: 19px;
            margin-left: 4px;
        }
    }

    .state-date {
        margin-top: 14px;
        margin-bottom: 12px;
        align-items: baseline;

        .date {
            color: #323232;
            font-size: 16px;
            line-height: 19px;
        }

        .state {
            color: #586f80;
            background: #f0f4f7;
            border-radius: 4px;
            padding: 4px 8px;
            margin-right: 13px;
            font-weight: 600;
        }
    }

    .member-slot {
        margin-bottom: 24px;
        color: #323232;
        font-size: 16px;
    }

    .delete-batch {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .delete-icon {
            margin-top: 30px;

            span {
                margin-left: 8px;
                font-size: 16px;
                color: #637f92;
                font-weight: 600;
            }
        }
    }
}

// for table

.draggable-table {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    thead {
        tr {
            background: #f8fafb;
        }
    }
    .serialNo {
        width: 58px;
    }

    thead,
    tbody {
        width: 100%;
        display: flex;
        flex-direction: column;

        .tableRow {
            cursor: grabbing;
        }

        tr {
            display: flex;
            justify-content: space-around;
            width: 100%;
            min-height: 65px;

            th,
            td {
                padding: 16px;
                border: 0.25px solid #e1e7ec;
                width: 25%;
                font-size: 16px;
                text-align: left;
                vertical-align: middle;
            }

            th {
                color: #637f92;
                font-weight: 600;
            }

            tr {
                font-weight: normal;
                color: #323232;
            }
        }
    }

    .table-action {
        margin-left: 10px;
        cursor: pointer;
    }
}
