* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.heading-btn-container {
    margin-bottom: -32px;

    .heading {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        margin-top: -20px;

        span {
            font-size: 16px;
            font-weight: 600;
            color: #323232;
        }

        button {
            span {
                font-size: 13px;
                font-weight: 600;
                text-align: center;
                color: white;
            }
        }
    }
}

.table-btn-container {
    display: flex;
    flex-direction: column;

    .week {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 14px 18px;
        height: 44px;
        font-weight: 600;
        font-size: 16px;
        color: #637f92;
        background: #f8fafb;
        border: 1px solid #e1e7ec;
        border-bottom: none;
        border-radius: 8px 8px 0px 0px;

        .deleteBtn {
            position: relative;
            top: 8px;
            right: 15px;
        }
    }

    .abstractTable {
        h2 {
            margin-bottom: 0 !important;
        }
    }
}

.add-btn {
    .ant-btn {
        background: #586f80;
        border-radius: 2px;
        height: 32px;
        width: 95px;
        color: white;
        font-weight: 600;
        text-align: center;
        transition: all 0.5s linear;
        border: none;

        &:hover,
        &:focus {
            color: white;
            background: #586f80;
            border: none;
        }
    }
}

.btn1 {
    .btn-container {
        margin: 0;
        display: flex;
        justify-content: flex-end;

        button {
            display: flex;
            align-items: center;
        }
    }

    .ant-btn {
        background: none;
        margin-top: 5px;
        border-radius: 2px;
        padding: 8px 12px;
        color: #586f80;
        font-weight: 600;
        text-align: right;
        transition: all 0.5s linear;
        border: none;
        box-shadow: none;

        &:hover,
        &:focus {
            color: #586f80;
            background: none;
            border: none;
        }
    }
}

// input-table-header
.input-table-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .heading {
        margin-bottom: 4px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        color: #323232;
    }

    .paragraph {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: -0.15px;
        color: #767676;
    }
}

[ant-click-animating-without-extra-node]:after {
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
}
