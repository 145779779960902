$text_color: #637f92;
$background_color: #f0f4f7;
$colorPrimary: #36a0f7;

.searchBoxContainer {
    display: flex;
    align-items: center;
    box-sizing: border-box;

    .searchBox {
        border-radius: 50px;
        padding: 2px 9px !important;
        background: $background_color;
        border: none;
        outline: none;
        color: $text_color;
        font-size: 16px;
        box-sizing: border-box;
        width: 240px;

        input {
            color: $text_color;
            background: $background_color;
        }

        .ant-input::placeholder {
            color: $text_color;
        }

        &:focus {
            border-color: $text_color !important;
            //box-shadow: 0 0 0 2px $colorPrimary !important;
            z-index: 5;
        }

        &:focus-within {
            border-color: $text_color;
            box-shadow: 0 0 0 1px $text_color;
        }

        &:hover {
            border: $colorPrimary;
        }

        .searchOutline {
            SVG {
                font-size: 18px;
            }
        }
    }
}

::placeholder {
    color: $text_color;
}

:-ms-input-placeholder {
    color: $text_color;
}

::-ms-input-placeholder {
    color: $text_color;
}
